import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components/native';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy'
import FileUpload from 'src/components/molecules/FileUpload/index';
import { CustomReactNativeFile } from '../../FileUpload/type';
import { FormSchema, FormHandleSubmit } from '../../Forms/types';
import { createDocumentObject } from 'src/utils/utility';
import { isWeb } from 'src/constant/device';
import { documentTypesQuery } from 'src/graphql/onboarding/form-content';
import { useLazyQuery } from '@apollo/client';
import { DefaultUserSelection, SchemaNameUserDocumentCategoryMapper } from 'src/constant';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';

type DocumentType = {
  typeId: string;
  name: string;
  label: string;
  category: string;
}

type DocType = {
  type: DocumentType
  document?: CustomReactNativeFile
}

export const MultiDocInput = (props: UseFieldApiConfig) => {
  const {
    input,
    label,
    subscribeToValues,
    meta,
    valueField,
    parentType
  } = useFieldApi(props);

  const isThreeColumnForm: boolean = !!props?.threeColumnForm;


  const formOptions = useFormApi();
  const { getState, change } = formOptions;
  const handleSubmit: any = formOptions.handleSubmit;
  const schema: FormSchema = formOptions.schema as FormSchema;
  const initialValues: any = formOptions.initialValues;
  const [fileErr, setFileErr] = useState<any>();
  const [docs, setDocs] = useState<DocType[]>([]);
  const [removeIdx, setRemoveIdx] = useState<number>(-1);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const { values, submitFailed, error } = getState();
  const { t } = useI18n();
  const { name, onChange } = input;
  const documentField = parentType ? values?.[`${parentType}`]?.[`${valueField}`] : values[valueField];
  let [getDocTypes, { data: allDocType }] = useLazyQuery(
    documentTypesQuery, {
    variables: {
      filters: {
        defaultUserSelection: { eq: DefaultUserSelection.ALLOWED },
        category: { eq: SchemaNameUserDocumentCategoryMapper[schema.name] }
      }
    },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (valueField && values && documentField) {
      setDocs(getAllDocsSyncWithNewlyAttachedDocs());
    } else {
      if (!optionsLoaded || docs.length == 0) {
        getDocTypes()
        setOptionsLoaded(true);
      }
    }
  }, [documentField])

  const getAllDocsSyncWithNewlyAttachedDocs = () => {
    let allDocs: any[] = Array.from(documentField);
    const documents = parentType ? values?.[parentType]?.documents : values?.documents;
    if (documents?.length > 0) { 
      documents.forEach((ndoc: any) => {
        if (ndoc.document) {
          const docidx = allDocs.findIndex((xdoc) => xdoc.type.typeId == ndoc.typeRef);
          if (docidx != -1)  {
            allDocs[docidx].document = ndoc.document;
          }
        }
      });
    }
    if (allDocs.length > 0) {
      allDocs = allDocs.map((xdoc: any) => {
        if (xdoc.document && !xdoc.document.id) {
          const idx = documents.findIndex((doc)=> doc.typeRef == xdoc.type.typeId && doc.document);
          if(idx == -1) {
            xdoc.document = null;
          }
        }
        return xdoc;
      })
    }
    return allDocs;
  }

  useEffect(() => {
    if (allDocType?.documentTypes?.data) {
      if (docs.length == 0) {
        const alldocs = allDocType?.documentTypes?.data.map((type: DocumentType)=> {
          return {
            document: null,
            type,
          }
        })
        setDocs(alldocs);
      }
    }
  }, [allDocType])

  const handleRemove = (index: number) => {
    return async (file: CustomReactNativeFile) => {
      let submitHandler: FormHandleSubmit = await handleSubmit();
      const updatedDocs = [...docs];
      if (docs && docs.length > 0) {
        const doc = docs?.[index]?.document;
        const detailId = parentType ? initialValues?.[`${parentType}`]?.id : initialValues?.id;
        if (doc && doc?.id && detailId && submitHandler) {
          submitHandler({
            removeDoc: {
              detail: schema.name,
              detailId: detailId,
              docId: doc.id
            }
          });
        }
        updatedDocs[index] = { type: docs[index].type };
        setDocs(updatedDocs);
        // remove doc from doc uploading field
        const typeRef = docs?.[index]?.type.typeId;
        const documents = parentType ? values?.[`${parentType}`]?.documents : values?.documents;
        if (documents?.length > 0) {
          const ddocs = documents?.filter((dd: any) => {
            if (dd.typeRef == typeRef) {
              return !dd.document;
            } else {
              return true;
            }
          });
          if (documents.length != ddocs.length) {
            console.log("ddocs", ddocs);
            onChange(ddocs);
          }
        }
      }
    }
  }


  const handleFilesAttach = (index: number) => {
    return async (files: CustomReactNativeFile[]) => {
      const updatedDocs = [...docs];
      const docType = updatedDocs[index]?.type;
      if (files?.length > 0  && docType) {
        const newDoc = {...updatedDocs[index]};
        newDoc.document = files[0];
        updatedDocs[index] = newDoc;
        const docObj: any = await createDocumentObject(
          files[0],
          docType.category,
          docType.name,
          docType.typeId
        )
        const documents = parentType ? values?.[`${parentType}`]?.documents : values?.documents;
        if (documents?.length > 0) {
          onChange([...documents, docObj]);
        } else {
          onChange([docObj]);
        }
        setTimeout(() => {
          // setting doc in seperate callback by using setTimeout.
          // since current one is not publishing the doc change effectively.
          setDocs(updatedDocs);
        })
      }
    }
  }

  const setError = (id: string, error: { message: string }) => {
    if (error.message) {
      setFileErr((prev: any) => ({ ...prev, [`${id}`]: error.message }))
    }
  }

  const clearErrors = () => {
    setFileErr(null);
  }

  function resolveComponent(component: JSX.Element) {
    if (isWeb) {
      return <WebInputContainer>
        <WebBrowseFile>
          {component}
        </WebBrowseFile>
      </WebInputContainer>
    } else {
      return <InputContainer>
        {component}
      </InputContainer>
    }
  }

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <>
          {
            !!label && Array.isArray(docs) && docs.length > 0 && (
            <>
              {isWeb ? (
                <HeaderWrapper>
                  <HeaderFive value={t(label)} color={colors.primaryText} />
                </HeaderWrapper>
              ) : (
                <SectionHeader>{t(label)}</SectionHeader>
              )}
              <BreakLine />
                {isThreeColumnForm ? <BreakLine /> : <></>}
            </>
            )
          }
          {docs.map((element: any, index: number) => {
          return resolveComponent(
            <FileUpload
              file={element?.document}
              name={element?.document?.name}
                type={"onboarding"}
              header={`${element?.type?.label}${element?.type?.defaultUserSelectionRequired ? '*' : ''}`}
              handleFileRemove={handleRemove(index)}
              handleFilesAttach={handleFilesAttach(index)}
              fieldId={parentType ? `${parentType}_${element?.type?.typeId}` : element?.type?.typeId}
              setError={setError}
              clearErrors={clearErrors}
              compact={true}
              width={"100%"}
              errorMsgText={
                (fileErr?.hasOwnProperty(element?.type?.typeId)
                  ? fileErr[`${element?.type?.typeId}`]
                    : "") ||
                (submitFailed && t(meta?.error))
              }
              fileTypeAllowed={
                element?.type?.label.toLowerCase().indexOf('signature') === -1 ? 'file' : 'image'
              }
            />
            );
          })}
        </>
      )}
    </FormSpy>
  );
}


const ErrorMsg = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;

const HeaderWrapper = styled.View`
  padding-bottom: 16px;
`;

const BreakLine = styled.View`
  margin-bottom: 0;
`;

const SectionHeader = styled.Text`
  padding-bottom: 16px;
  font-size: 16px;
  font-family: ${fonts.medium};
  color: ${colors.primaryText};
`;

const InputContainer = styled.View`
  margin-bottom: 10px;
`;

const WebInputContainer = styled.View`
  margin-bottom: 24px;
`;

const WebBrowseFile = styled.View`
  width: 100%;
  box-sizing: border-box;
  margin-top: 0px;
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;